import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { trackDownloadOwnersManualPDF } from 'tw-oi-core/services/analytics'

import { UI } from '../config'

import '../styles/OwnersManualPdfs.scss'
import Icon from './Icon'
import { rem } from 'tw-oi-core/utils/ui'

export default class OwnersManualPdf extends Component {
  static propTypes = {
    ownersManualPdfs: ImmutablePropTypes.list,
    model: PropTypes.string,
  }

  renderItems(ownersManualPdfs) {
    return ownersManualPdfs.flatMap(
      (item, index, array) => {
        const value = <a
          key={item.get('id')}
          href={item.getIn(['bodyPdf', 'url'])}
          className='pdf-link'
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackDownloadOwnersManualPDF(this.props.model, item.get('language'))}
        >
          {UI.OWNERS_MANUAL_PDF_TITLE[item.get('locale')]} <span style={{maxWidth: rem(22)}}><Icon type='download' /></span>
        </a>
        // check for the last item
        return array.size - 1 !== index ? [value, <span key={`separator-${index}`} className='separator'>/</span>] : [value]
      }
    )
  }

  render() {
    const {ownersManualPdfs} = this.props
    if (!ownersManualPdfs || ownersManualPdfs.size === 0) {
      return null
    }

    return <div className='OwnersManualPdfs'>
      <span className='label'>Owner’s Manual PDF:</span>
      {this.renderItems(ownersManualPdfs)}
    </div>
  }
}
