import React from 'react'

export function downloadPdf() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 4C12.2761 4 12.5 4.22386 12.5 4.5V14.7929L16.6464 10.6464C16.8417 10.4512 17.1583 10.4512 17.3536 10.6464C17.5488 10.8417 17.5488 11.1583 17.3536 11.3536L12.3536 16.3536C12.3056 16.4015 12.2504 16.4377 12.1914 16.4621C12.1333 16.4861 12.0697 16.4996 12.003 16.5C12.002 16.5 12.001 16.5 12 16.5C11.999 16.5 11.998 16.5 11.997 16.5C11.87 16.4992 11.7433 16.4504 11.6464 16.3536L6.64645 11.3536C6.45118 11.1583 6.45118 10.8417 6.64645 10.6464C6.84171 10.4512 7.15829 10.4512 7.35355 10.6464L11.5 14.7929V4.5C11.5 4.22386 11.7239 4 12 4ZM4.5 16C4.77614 16 5 16.2239 5 16.5V19H19V16.5C19 16.2239 19.2239 16 19.5 16C19.7761 16 20 16.2239 20 16.5V19.5C20 19.7761 19.7761 20 19.5 20H4.5C4.22386 20 4 19.7761 4 19.5V16.5C4 16.2239 4.22386 16 4.5 16Z" 
      stroke="#404040" 
      fill="#404040"
      strokeWidth={0.2}
      />
    </svg>
  )
}
