import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getConfig } from 'tw-oi-core'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { trackBrowseTopicClick, trackBrowseFolderClick } from 'tw-oi-core/services/analytics'
import { recalculateTopicsHeightForIOS } from '../extras/utils'

import { DEFAULT_PUBLICATION_TYPE, ROUTE, UI } from '../config'

import { connect } from 'react-redux'

import ContentList from '../components/ContentList'
import ScreenHead from '../components/ScreenHead'
import Media from '../components/Media'
import Loader from '../components/Loader'
import ScreenContainer from '../components/ScreenContainer'
import AutoBreadCrumb from '../components/AutoBreadCrumb'

import '../styles/Topics.scss'
import { getRootFolderResourceKey, getParentFolder, isRootFolder, isFolder, isCollapsedFolder, isDitaTopic } from '../extras/utils'
import StickyBar from '../components/StickyBar'
import OwnersManualPdfs from '../components/OwnersManualPdfs'
import { rem } from 'tw-oi-core/utils/ui'
import CustomModal from '../components/customModal'
import Icon from '../components/Icon'
class BrowseDita extends Component {

  static propTypes = {
    ownersManualPdfs: ImmutablePropTypes.list,
    fetching: PropTypes.bool,
    publications: ImmutablePropTypes.list,
    topics: ImmutablePropTypes.list,
    folders: ImmutablePropTypes.list,
    foldersIndex: ImmutablePropTypes.map,
    currentFolder: PropTypes.string,
    currentYear: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    match: PropTypes.object.isRequired,
    baseRoute: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    userMedia: PropTypes.shape({
      isDesktop: PropTypes.bool,
    })
  }

  constructor(props) {
    super(props)

    const { BROWSE_ROOT_KEY } = getConfig()
    const { foldersIndex, topics, baseRoute, match, history, publications } = props
    const rootFolderResourceKey = getRootFolderResourceKey(publications, DEFAULT_PUBLICATION_TYPE, foldersIndex, BROWSE_ROOT_KEY)
    let folderId = match.params.folderId || rootFolderResourceKey

    let activeFolder = (foldersIndex && foldersIndex.size) ? foldersIndex.get(folderId) : null
    if (!isFolder(activeFolder.toJS())) {
      let possibleTopic = topics.find(item => item.get('resourceKey') === match.params.folderId)
      // redirect to topic if trying to open folder with id of topic
      if ((activeFolder && isCollapsedFolder(activeFolder.toJS())) || (possibleTopic && isDitaTopic(possibleTopic.toJS()))) {
        const url = `${baseRoute}${ROUTE.BROWSE}${ROUTE.TOPIC}/${match.params.folderId}`
        history.replace(url)
        activeFolder = null
      }
    }
    const isActiveFolderRoot = isRootFolder(activeFolder, rootFolderResourceKey)
    this.state = {
      isDownloadOwnerManualModalOpen: false,
      sortDescend: false,
      activeFolder,
      rootFolderResourceKey,
      isActiveFolderRoot,
      parentFolder: activeFolder && !isActiveFolderRoot ? getParentFolder(activeFolder, foldersIndex) : null
    }
  }

  componentDidUpdate(prevProps) {
    const { rootFolderResourceKey } = this.state

    if (prevProps.match.params.folderId !== this.props.match.params.folderId) {
      const { foldersIndex, match } = this.props

      const folderId = match.params.folderId || rootFolderResourceKey
      const activeFolder = (foldersIndex && foldersIndex.size) ? foldersIndex.get(folderId) : null
      const isActiveFolderRoot = isRootFolder(activeFolder, rootFolderResourceKey)
      this.setState({
        activeFolder,
        isActiveFolderRoot,
        parentFolder: activeFolder && !isActiveFolderRoot ? getParentFolder(activeFolder, foldersIndex) : null
      })

      recalculateTopicsHeightForIOS()
    }
  }

  /**
   * Triggers browse analytics event when item is clicked
   *
   * @param {Object} item
   */
  trackItem(item) {
    switch (item.type) {
      case 'folder':
        isCollapsedFolder(item) ? trackBrowseTopicClick(item.title) : trackBrowseFolderClick(item.title)
        break
      case 'topic':
        trackBrowseTopicClick(item.title)
        break
      default:
      // Do nothing in case of unsupported content type
    }
  }

  render() {
    const { BROWSE_ROOT_KEY } = getConfig()
    const { currentBrand, currentYear, currentModel, baseRoute, fetching, match, userMedia, foldersIndex, ownersManualPdfs } = this.props
    const { rootFolderResourceKey, isActiveFolderRoot, parentFolder, activeFolder } = this.state
    const contents = activeFolder ? activeFolder.get('contents') : null

    if (!activeFolder) {
      return <Loader type="status" className="inverse">Folder not found</Loader>
    }
    return (
      <div className="Topics">
        <ScreenHead
          back={parentFolder ? `${baseRoute}${ROUTE.BROWSE}${ROUTE.FOLDER}/${parentFolder.get('resourceKey') ? parentFolder.get('resourceKey') : BROWSE_ROOT_KEY}` : null}
          backTitle={parentFolder && !isRootFolder(parentFolder, rootFolderResourceKey) ? parentFolder.get("title") : UI.OWNERS_MANUAL}
          ownersManualPdfs={ownersManualPdfs}
        >
          <em>{currentYear} {currentBrand}</em>
          <span>{currentModel}</span>
        </ScreenHead>

        <Media type="mobile">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className='topics-subtitle'>
            {isActiveFolderRoot ? UI.OWNERS_MANUAL : activeFolder.get('title')}
            {
              ownersManualPdfs?.size ? <div style={{ width: rem(23) }} onClick={() => { this.setState({ isDownloadOwnerManualModalOpen: true }) }} className='icon-container'><Icon type='download' /></div> : <></>
            }
          </div>
        </Media>
        {
          this.state.isDownloadOwnerManualModalOpen && <CustomModal onClose={()=>{this.setState({isDownloadOwnerManualModalOpen: false})}}>
            <div className='modal-container'>
              <div className='modal-header'>
                <div>{currentYear} {currentBrand} {currentModel}</div>
                <div>Download PDF</div>
              </div>
              <div className='modal-body'>
                {
                  ownersManualPdfs?.map((item,index)=>{
                    return <a href={item.getIn(['bodyPdf', 'url'])} rel="noopener noreferrer" target="_blank" className='option-container' key={index}>
                      <span className='option-title'>{UI.OWNERS_MANUAL_PDF_TITLE[item.get('locale')]}</span>
                      <div className='icon-container'>
                       <Icon type='download'/>
                      </div>
                    </a>
                  })
                }
              </div>
              <div onClick={()=>{this.setState({isDownloadOwnerManualModalOpen: false})}} className='modal-footer'>
                Close
              </div>
            </div>
          </CustomModal>
        }
        <div className="screen-content">
          <Media type="desktop">
            <StickyBar className="sticky-topics-bar">
              <div className="topics-bar">
                <AutoBreadCrumb {...{ activeFolder, foldersIndex, baseRoute, rootFolderResourceKey }} />
                <OwnersManualPdfs
                  model={currentModel}
                  ownersManualPdfs={ownersManualPdfs}
                />
              </div>
            </StickyBar>
          </Media>
          <ScreenContainer currentUrl={match.url}>
            <div className="topics">
              {fetching ? <Loader className="inverse" /> :
                <ContentList
                  isDesktop={userMedia.isDesktop}
                  topics={Object.values(contents.toJS())}
                  baseRoute={baseRoute + ROUTE.BROWSE}
                  onClick={(topic) => this.trackItem(topic)}
                />
              }
            </div>
          </ScreenContainer>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ contents, vehicle, user }) {
  const { topics, folders, foldersIndex, publications, fetching, currentAsset, fetchingAsset, currentFolder } = contents
  const { currentYear, currentModel, currentBrand } = vehicle
  const { media: userMedia } = user

  return { topics, folders, foldersIndex, publications, fetching, currentAsset, fetchingAsset, currentYear, currentModel, currentBrand, currentFolder, userMedia }
}

export default connect(mapStateToProps)(BrowseDita)
