import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { trackContentViewPdf, trackContentViewByType, trackContentView } from 'tw-oi-core/services/analytics'

import { CONTENT_TYPE, ROUTE } from '../config'
import { getStartingBreadCrumb } from '../extras/utils'
import ScreenHead from '../components/ScreenHead'
import BreadCrumb from '../components/BreadCrumb'
import StickyBar from '../components/StickyBar'
import Media from '../components/Media'
import { ScrollSink } from '../components/MotionScroll'
import { bindActionCreators } from 'redux'
import * as UserActions from 'tw-oi-core/actions/UserActions'
import { isPdf } from '../extras/utils'
import PdfRenderer from '../components/PdfRenderer'

import '../styles/PdfViewer.scss'
import { BRAND_SPECIFIC_TAG } from './BrandSpecificInformation'

const PdfViever = ({
  baseRoute,
  ownersManualPdfs,
  match,
  publications,
  UserActions,
  currentYear,
  currentModel,
  currentBrand,
  currentProductType,
  currentProductSubtype,
}) => {
  const [pdf, breadcrumbs] = useMemo(() => {
    const doc = publications.filter(isPdf).find(pub => pub.resourceKey === match.params.pdfResourceKey)

    const breadcrumbs = [
      { title: getStartingBreadCrumb(match.params.referrer), link: `${baseRoute}${match.params.referrer}` },
    ]

    if (match.params.referrer === ROUTE.BROWSE && !match.params.brandSpecific) {
      breadcrumbs.push({ title: 'Component Manuals', link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.PDFS}` })
    }

    if (match.params.brandSpecific) {
      breadcrumbs.push({ title: BRAND_SPECIFIC_TAG, link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.BRAND_SPECIFIC}` })
    }

    if (match.params.pseudoFolderName) {
      breadcrumbs.push({
        title: match.params.pseudoFolderName,
        link: `${baseRoute}${ROUTE.BROWSE}${ROUTE.PDFS}/${match.params.pseudoFolderName}`,
      })
    }

    breadcrumbs.push({ title: doc.title, link: '' })

    return [doc, breadcrumbs]
  }, [match])

  useEffect(() => {
    trackContentView(pdf.title)
    trackContentViewPdf(pdf.title)
    trackContentViewByType(pdf.title, CONTENT_TYPE.PDF)
    UserActions.addRecent(
      currentYear,
      currentModel,
      pdf.resourceKey,
      currentBrand,
      currentProductType,
      currentProductSubtype
    )
  }, [pdf.resourceKey, currentYear, currentModel, currentBrand, currentProductType, currentProductSubtype])

  return (
    <div className='PdfViewer Topics one'>
      <ScreenHead
        landscapeOverlay={true}
        ownersManualPdfs={ownersManualPdfs}
        back={
          match.params.pseudoFolderName
            ? `${baseRoute}${ROUTE.BROWSE}${ROUTE.PDFS}/${match.params.pseudoFolderName}`
            : `${baseRoute}${match.params.referrer}`
        }
        backTitle={match.params.pseudoFolderName || getStartingBreadCrumb(match.params.referrer)}
      >
        <em>
          {currentYear} {currentBrand}
        </em>
        <span>{currentModel}</span>
      </ScreenHead>

      <Media type='mobile'>
        <div className='topics-subtitle'>{pdf.title}</div>
      </Media>

      <div className='screen-content'>
        <Media type='desktop'>
          <StickyBar className='sticky-topics-bar'>
            <div className='topics-bar'>
              <BreadCrumb items={breadcrumbs} />
            </div>
          </StickyBar>

          <div className='screen-container'>
            <div className='content-container'>
              <div className='content-container-wrapper'>
                <PdfRenderer pdfUrl={pdf.bodyPdf.url} title={pdf?.title}/>
              </div>
            </div>
          </div>
        </Media>

        <Media type='mobile'>
          <ScrollSink scrollTop={0} resetScroll={true}>
            <div className='screen-container'>
              <PdfRenderer pdfUrl={pdf.bodyPdf.url} title={pdf?.title}/>
            </div>
          </ScrollSink>
        </Media>
      </div>
    </div>
  )
}

PdfViever.propTypes = {
  baseRoute: PropTypes.string,
  ownersManualPdfs: PropTypes.array,
  currentYear: PropTypes.string,
  currentModel: PropTypes.string,
  currentBrand: PropTypes.string,
  currentProductType: PropTypes.string,
  currentProductSubtype: PropTypes.string,
  match: PropTypes.object,
  publications: PropTypes.array,
  contents: PropTypes.array,
  UserActions: PropTypes.shape({
    addRecent: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = ({ vehicle, contents }) => {
  const { currentYear, currentModel, currentBrand, currentProductType, currentProductSubtype } = vehicle
  const { publications } = contents

  return {
    currentYear,
    currentModel,
    currentBrand,
    currentProductType,
    currentProductSubtype,
    publications: publications.toJS(),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfViever)
