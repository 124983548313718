import React from 'react'
import PropTypes from 'prop-types'

import './CustomModal.scss'

const CustomModal = ({ children }) => {

    return (
        <div className='FeedbackModal'>
            {children}
        </div>
    )
}

CustomModal.propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.any
}

export default CustomModal
