/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { zoomPlugin } from '@react-pdf-viewer/zoom'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import '@react-pdf-viewer/page-navigation/lib/styles/index.css'
import '@react-pdf-viewer/zoom/lib/styles/index.css'
import { pdfjs } from 'react-pdf'
import { connect } from 'react-redux'
import Icon from './Icon'
import Media from './Media'

const PdfRenderer = ({ pdfUrl }) => {
  const pageNavigationPluginInstance = pageNavigationPlugin()
  const zoomPluginInstance = zoomPlugin()

  const { GoToPreviousPage, GoToNextPage, CurrentPageInput, NumberOfPages } = pageNavigationPluginInstance
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance

  return (
    <div className='pdf-renderer-conatiner'>
      <Worker workerUrl={`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`}>
        <Media type='desktop'>
          <div className='desktop-conatiner'>
            <div className='page-control-conatiner'>
              <span className='title'>Page</span>
              <CurrentPageInput style={{ padding: '4px', borderRadius: '4px', backgroundColor: '#ffffff', border: '1px solid #ddd' }} /> / <NumberOfPages/>
              <GoToPreviousPage style={{ padding: '4px', borderRadius: '4px', backgroundColor: '#e9ecef' }} />
              <GoToNextPage style={{ padding: '4px', borderRadius: '4px', backgroundColor: '#e9ecef' }} />
            </div>
            <div className='zoom-control-conatiner'>
              <ZoomPopover style={{ padding: '4px', borderRadius: '4px', backgroundColor: '#e9ecef' }} />
              <ZoomOutButton style={{ padding: '4px', borderRadius: '4px', backgroundColor: '#e9ecef' }} />
              <ZoomInButton style={{ padding: '4px', borderRadius: '4px', backgroundColor: '#e9ecef' }} />
            </div>
            <div className='download_only'>
              <a href={pdfUrl} target='_blank' rel='noreferrer'>
                <Icon type='downloadPdf' />
              </a>
            </div>
          </div>
        </Media>
        <Media type='mobile'>
          <div className='mobile-conatiner'>
            <div className='page-control-conatiner'>
              <GoToPreviousPage style={{ padding: '4px', borderRadius: '4px', backgroundColor: '#e9ecef' }} />
              <GoToNextPage style={{ padding: '4px', borderRadius: '4px', backgroundColor: '#e9ecef' }} />
            </div>
            <div className='zoom-control-conatiner'>
              <ZoomOutButton style={{ padding: '4px', borderRadius: '4px', backgroundColor: '#e9ecef' }} />
              <ZoomInButton style={{ padding: '4px', borderRadius: '4px', backgroundColor: '#e9ecef' }} />
            </div>
            <div className='download_only'>
              <a href={pdfUrl} target='_blank' rel='noreferrer'>
                <Icon type='downloadPdf' />
              </a>
            </div>
          </div>
        </Media>
        <Viewer fileUrl={pdfUrl} plugins={[pageNavigationPluginInstance, zoomPluginInstance]} />
      </Worker>
    </div>
  )
}

PdfRenderer.displayName = 'PdfRenderer'

PdfRenderer.propTypes = {
  pdfUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default connect()(PdfRenderer)